/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/Other/javascript.js to edit this template
 */
import * as THREE from 'three';
import * as YUKA from 'yuka';
import {OrbitControls}
from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader}
from 'three/examples/jsm/loaders/GLTFLoader.js';

import {createGraphHelper}
from './graph_helper.js';
import {createConvexRegionHelper}
from './convex_helper.js';


import { FBXLoader }
from 'three/examples/jsm/loaders/FBXLoader';
import Stats from 'three/examples/jsm/libs/stats.module';

import { Pathfinding, PathfindingHelper } from 'three-pathfinding';


// SCENE
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xa8def0);

// CAMERA
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.y = 10;
camera.position.z = 10;
camera.position.x = 33;

// RENDERER
const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(window.devicePixelRatio);
renderer.shadowMap.enabled = true;

// ORBIT CAMERA CONTROLS
const orbitControls = new OrbitControls(camera, renderer.domElement);
orbitControls.mouseButtons = {
    RIGHT: THREE.MOUSE.ROTATE,
    LEFT: THREE.MOUSE.PAN
};
orbitControls.enableDamping = true;
orbitControls.enablePan = true;
orbitControls.minDistance = 5;
orbitControls.maxDistance = 100;
orbitControls.maxPolarAngle = Math.PI / 2 - 0.05;// prevent camera below ground
orbitControls.minPolarAngle = Math.PI / 4;  // prevent top down view
orbitControls.update();

// LIGHTS
const dLight = new THREE.DirectionalLight('white', 0.8);
dLight.position.x = 20;
dLight.position.y = 30;
dLight.castShadow = true;
dLight.shadow.mapSize.width = 4096;
dLight.shadow.mapSize.height = 4096;
const d = 35;
dLight.shadow.camera.left = -d;
dLight.shadow.camera.right = d;
dLight.shadow.camera.top = d;
dLight.shadow.camera.bottom = -d;
scene.add(dLight);

const aLight = new THREE.AmbientLight('white', 0.5);
scene.add(aLight);

// ATTACH RENDERER
document.body.appendChild(renderer.domElement);

// RESIZE HANDLER
function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
}
window.addEventListener('resize', onWindowResize);


const agentHeight = 1.0;
const agentRadius = 0.25;
const agent = new THREE.Mesh(new THREE.CylinderGeometry(agentRadius, agentRadius, agentHeight), new THREE.MeshPhongMaterial({color: 'green'}));
agent.position.y = agentHeight / 2;
const agentGroup = new THREE.Group();
agentGroup.add(agent);
agentGroup.position.z = 0;
agentGroup.position.x = 0;
agentGroup.position.y = 1;
scene.add(agentGroup);

const time = new YUKA.Time();

const loader = new GLTFLoader();



loader.load('./assets/jbr/JBR_mesh.glb', function (gltf) {
    scene.add(gltf.scene);
});



let nav, vehicle;
let line, linegeometry;

const materialLine = new THREE.LineBasicMaterial({color: 0xFFFFFF});



const pathfinding = new Pathfinding();
const pathfindinghelper = new PathfindingHelper();
scene.add(pathfindinghelper);

const ZONE = 'level1';
const SPEED = 5;
let navmesh;
let groupID;
let navpath;
loader.load('./assets/jbr/JBR_navmesh.glb', function (gltf) {
    // scene.add(gltf.scene);
    gltf.scene.traverse((node) => {
        if (!navmesh && node.isObject3D && node.children && node.children.length > 0) {
            navmesh = node.children[0];
            pathfinding.setZoneData(ZONE, Pathfinding.createZone(navmesh.geometry));
        }
    });
});

// RAYCASTING
const raycaster = new THREE.Raycaster(); // create once
const clickMouse = new THREE.Vector2();  // create once

function intersect(pos) {
    raycaster.setFromCamera(pos, camera);
    return raycaster.intersectObjects(scene.children);
}
window.addEventListener('click', event => {
    // THREE RAYCASTER
    clickMouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    clickMouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    const found = intersect(clickMouse);
    if (found.length > 0) {
        let target = found[0].point;
        const agentpos = agentGroup.position;
        // console.log(`agentpos: ${JSON.stringify(agentpos)}`);
        // console.log(`target: ${JSON.stringify(target)}`);

        groupID = pathfinding.getGroup(ZONE, agentGroup.position);
        // find closest node to agent, just in case agent is out of bounds
        const closest = pathfinding.getClosestNode(agentpos, ZONE, groupID);
        navpath = pathfinding.findPath(closest.centroid, target, ZONE, groupID);
        if (navpath) {
            // console.log(`navpath: ${JSON.stringify(navpath)}`);
            pathfindinghelper.reset();
            pathfindinghelper.setPlayerPosition(agentpos);
            pathfindinghelper.setTargetPosition(target);
            pathfindinghelper.setPath(navpath);
        }
    }
})

let secs = 0,
fps_update = 20,
fps_movement = 30,
frame = 0,
frameMax = 300;
// MOVEMENT ALONG PATH
function move(delta) {
    if (!navpath || navpath.length <= 0)
        return;

    let targetPosition = navpath[ 0 ];
    const distance = targetPosition.clone().sub(agentGroup.position);

    const per = frame / frameMax,
    bias = (1 - Math.abs(per - 0.5) / 0.5);
    // MOVEING THE MESH OBJECT
//    mesh.position.x = -5 + 10 * bias
    // SETTING POSITION OF THE CAMERA RELATIVE TO THE POSITION OF THE MESH
    camera.position.copy(agentGroup.position).add( new THREE.Vector3(agentGroup.position.x, 10, 20) );
    // CALLING THE LOOKAT METHOD OF THE CAMERA
   // camera.lookAt(agentGroup.position);



    if (distance.lengthSq() > 0.05 * 0.05) {
        distance.normalize();
        // Move player to target
        agentGroup.position.add(distance.multiplyScalar(delta * SPEED));
    } else {
        // Remove node from the path we calculated
        navpath.shift();
    }
}

// GAMELOOP
const clock = new THREE.Clock();
let gameLoop = () => {
    move(clock.getDelta());
    orbitControls.update();
    updateCamera();
    renderer.render(scene, camera);
    requestAnimationFrame(gameLoop);
};
gameLoop();


function updateCamera() {
    const time = clock.getElapsedTime();
    const looptime = 20;
    const t = (time % looptime) / looptime;
    const t2 = ((time + 0.1) % looptime) / looptime;

    if (!navpath || navpath.length <= 0)
        return;
    console.log(t + "," + t2);
    //console.log(navpath)

//  const pos = navpath[0];
//  const pos2 = navpath[1];
//	
//  camera.position.copy(pos);
//  camera.lookAt(pos2);
}